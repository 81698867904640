<template>
  <!-- 院内信息 -->
  <div class="information">
    <!-- 医院信息 -->
    <div class="message">
      <!-- 医院图标 -->
      <div>
        <img
          class="imgsize"
          src="../../static/img/ninghaiCounty.webp"
        />
      </div>
      <div class="hospital-name">
        <!-- 医院名称 -->
        <div class="name large">
          <span>廊坊市妇幼保健院</span>
        </div>
        <!-- 医院等级 -->
        <div class="level">
          <span class="small-size">二级甲等医院</span>
        </div>
      </div>
    </div>
    <div class="hospitalInfo">
      <!-- 咨询电话 -->
      <div class="telephone in-size">
        <div class="mobile">咨询电话：0316-2336002</div>
        <div @click="callPhone('0316-2336002')">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href='#icon-dianhua2-fuyou-copy'></use>
          </svg>
        </div>
      </div>
      <!-- 地址 -->
      <div class="address in-size">
        <div class="addressinfo">河北省廊坊市广阳区广阳道150号</div>
        <div>
          <svg
            class="icon"
            aria-hidden="true"
            @click="checkMap"
          >
            <use xlink:href='#icon-icon-test-fuyou-copy'></use>
          </svg>
        </div>
      </div>
      <!-- 公交 -->
      <div class="transit in-size">
        <div class="transitinfo">公交：13路AB、14路、22路、26路AB 、30路B、36路市交通运输局站下；1路AB、16路、34路华腾家园小区站下</div>
        <div>
          <svg
            class="icon"
            aria-hidden="true"
            @click="checkMap"
          >
            <use xlink:href='#icon-gongjiao'></use>
          </svg>
        </div>
      </div>
    </div>
    <!-- 医院介绍 -->
    <div class="hospitalIntroduce">
      <div class="course">
        <div class="courseTitle">医院历程</div>
        <div class="courseContent">廊坊市妇幼保健院始建于1962年，前身为天津地区妇幼卫生站、廊坊地区妇幼保健站、廊坊市妇幼保健站，1995年8月7日更名为廊坊市妇幼保健中心。为了进一步明确功能定位，加强规范化管理，有利于保健与临床业务同步发展，2020年6月16日更名为廊坊市妇幼保健院，是三级妇幼保健机构。</div>
      </div>
      <div class="development">
        <div class="developmentTitle">医院发展</div>
        <div class="developmentContent">设有产科、妇科、孕产保健科、手术室、麻醉科、儿科、乳腺科、乳腺筛查科、妇保科、儿保科、新生儿疾病筛查中心、产前诊断中心、口腔科、检验科、超声科、影像科、内科、中医科等28个科室的，全市唯一一家集保健、预防、医疗、教学、科研为一体的市级妇幼保健机构。是全市新生儿疾病筛查、儿童听力筛查、先心病筛查、产前筛查、婚前医学检查、爱婴医院的技术指导中心。先后与北京首都儿科研究所、北京大学口腔医院、北京同仁医院、北京市通州区妇幼保健院、河北省儿童医院等知名医院建立了长期合作机制。
          <img
            width="100%"
            src="../../static/img/jieshao1.jpg"
            alt=""
          >
          廊坊市妇幼保健院立足于为妇女儿童服务，以群体保健为基础，以儿科、妇科，产科为龙头科室，不断拓展业务内涵，加快专科建设，2018年10月23日借助京津冀合作的有力契机，与北京市通州区妇幼保健院签订医疗技术合作协议，成立了廊坊市妇产医院；2018年10月25日，与北京首都儿科研究所签订技术合作协议，成立了廊坊市妇产医院；2018年10月25日，与北京首都儿科研究所签订技术合作协议，成立了廊坊市儿童医院；北京首都儿科研究所和北京通州区妇幼保健院通过在人才培养、专题培训、远程授课、现场教学指导等方面给予支持，通过“传、帮、带”等措施，提高妇产、儿童医院整体水平，定期或长期派驻专家坐诊，让廊坊的妇女儿童在家门口就可以享受到来自北京的优质医疗服务，大大提高了老百姓的获得感与幸福感。
        </div>
      </div>
      <div class="honor">
        <div class="honorTitle">医院荣誉</div>
        <div class="honorContent">甘于奉献、敢于创新、拼搏进取、厚德载物的妇幼人，先后获得了“河北省巾帼文明示范岗”、“省青年文明号”、“省妇幼卫生工作先进集体”、“河北省三八红旗集体”、“全国巾帼文明岗”、“全国三八红旗集体”，2012年11月被卫生部妇幼司授予妇幼保健专科建设贡献奖；2011年以来连续被授予“五颗星”星级窗口单位；2012年以来连续9年被省委省政府授予省级文明单位；2010年以来，连续被中国癌症基金会授予庆“三八”妇女节大型公益活动“公益奖”；2018年1月被廊坊市精神文明建设委员会、廊坊市志愿服务联合会授予“廊坊市最佳服务组织荣誉称号”；2018年11月被中国妇幼保健协会评为“2018年度妇儿健康与出生缺陷防控项目地市级先进单位”；2019年因连续10年参加庆“三八”妇女节大型公益活动，有力推动了我国女性肿瘤防治公益事业发展，被中国癌症基金会授予“杰出贡献奖”；2020年4月被廊坊市委宣传部评为“最美抗疫卫生健康先进集体”；2020年7月被市直工委评为先进基层党组织。
          <img
            width="100%"
            src="../../static/img/jieshao2.jpg"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Bscroll from '@/components/content/scroll/BScroll'
export default {
  components: {
    // Bscroll
  },
  mounted () {
    // setTimeout(() => {
    //   this.$refs.scroll.refresh()
    // }, 300)
  },
  methods: {
    checkMap: function () {
      window.location.href = "http://apis.map.qq.com/uri/v1/marker?type=0&marker=coord:39.5398,116.7075;title:廊坊市妇幼保健中心;addr:河北省廊坊市广阳区广阳道168号"
    },
    // 调用拨号功能
    callPhone (phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    }
  },
}
</script>

<style lang="less" scoped>
.information {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 3.75rem);
  overflow: auto;
  .message {
    display: flex;
    align-items: center;
    background: @cardColor;
    border-radius: 0 0 5px 5px;
    // box-shadow: 2px 2px 2px 1px #b5b4b4;
    height: 8rem;
    .imgsize {
      width: 5.5rem;
      height: 5.5rem;
      margin: 1rem;
      margin-left: 2rem;
    }
    .hospital-name {
      padding-left: 2rem;
      .name {
        line-height: 3rem;
      }
      .level {
        text-align: center;
        border: 1px solid @lineColor;
        border-radius: 2rem;
        line-height: 1.5rem;
      }
    }
  }
  .hospitalInfo {
    padding: 0.5rem;
    margin: 0.7rem 0.5rem;
    box-shadow: 0px 0px 0.1rem 0.15rem #dfdcdc;
    border-radius: 10px;
    .telephone {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      border-bottom: 1px solid @lineColor;
      background-color: #ffffff;
      .mobile {
        flex: 3;
        line-height: 1.7rem;
      }
      .icon {
        height: 1.7rem;
        width: 1.7rem;
      }
    }
    .address {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid @lineColor;
      // border-bottom: 0.3rem solid @lineColor;
      background-color: #ffffff;
      .addressinfo {
        flex: 4;
        line-height: 1.7rem;
      }
      .icon {
        height: 1.7rem;
        width: 1.7rem;
      }
    }
    .transit {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      padding-bottom: 0.5rem;
      background-color: #ffffff;
      .transitinfo {
        flex: 4;
      }
      .icon {
        margin-top: 50%;
        // transform: translateY(0);
      }
    }
  }
  .hospitalIntroduce {
    padding-top: 0.1rem;
    margin: 1rem 0.5rem 2rem 0.5rem;
    box-shadow: 0px 0px 0.1rem 0.15rem #dfdcdc;
    border-radius: 10px;
    .course {
      margin: 1.5rem 1rem 0rem 0.5rem;
      border-top: 0.01px solid #000;
      position: relative;
      .courseTitle {
        position: absolute;
        top: -15px;
        line-height: 2rem;
        padding: 0 0.5rem;
        background-color: @lightColor;
        border-radius: 15px;
        color: #fff;
      }
      .courseContent {
        padding: 1.2rem 0.3rem 1.2rem 0.7rem;
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
    }
    .development {
      margin: 0.5rem 1rem 0rem 0.5rem;
      border-top: 0.5px solid #000;
      position: relative;
      .developmentTitle {
        position: absolute;
        top: -15px;
        line-height: 2rem;
        padding: 0 0.5rem;
        background-color: @lightColor;
        border-radius: 15px;
        color: #fff;
      }
      .developmentContent {
        padding: 1.2rem 0.3rem 1.2rem 0.7rem;
        font-size: 0.9rem;
        line-height: 1.5rem;
        img {
          margin: auto;
        }
      }
    }
    .honor {
      margin: 0.5rem 1rem 0rem 0.5rem;
      border-top: 0.5px solid #000;
      position: relative;
      .honorTitle {
        position: absolute;
        top: -15px;
        line-height: 2rem;
        padding: 0 0.5rem;
        background-color: @lightColor;
        border-radius: 15px;
        color: #fff;
      }
      .honorContent {
        padding: 1.2rem 0.3rem 1.2rem 0.7rem;
        font-size: 0.9rem;
        line-height: 1.5rem;
        img {
          margin: auto;
        }
      }
    }
  }
}
</style>